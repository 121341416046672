import { Typography } from "@material-tailwind/react";
import { CardBody, CardFooter, Button } from "@material-tailwind/react";
import Image from "next/image";
import { CiGlobe } from "react-icons/ci";
import { IoCall } from "react-icons/io5";
import Link from "next/link";
import { useState } from "react";
import Rating from "../Rating/Rating";

export function TooltipWithHelperIcon({ props }) {
  const fullUrl = window.location.href;
  const [data, setData] = useState([props]);

  return (
    <>
      {data.map((d, index) => (
        <div key={index}>
          <CardBody>
            <Typography variant="h5" color="black" className="mb-2">
              {d.businessName}
            </Typography>
          </CardBody>
          <CardFooter className="pt-0 flex">
            <div className="w-[40%]">
              <Image
                src="/assets/img/icons/accountant.svg"
                width={500}
                height={500}
                className="h-20 w-20"
                alt="image"
              ></Image>
            </div>
            <div className="w-[60%]">
              {d.phoneNumber ? (
                <div className="flex items-center gap-2 pb-3">
                  <div className="text-blue-700">
                    <IoCall />
                  </div>
                  <div>{d.phoneNumber}</div>
                </div>
              ) : (
                ""
              )}

              {d.website ? (
                <div className="flex gap-2 items-center pb-3">
                  <div className="text-blue-700">
                    <CiGlobe />
                  </div>
                  <div className="truncate">{d.website}</div>
                </div>
              ) : (
                ""
              )}

              <Rating rating={d.rating} numReviews={d.numReviews} />

              <Link href={`/places/${d.businessSlug}`}>
                <Button className="w-full bg-blue-400">Know More</Button>
              </Link>
              <div></div>
            </div>
          </CardFooter>
        </div>
      ))}
    </>
  );
}
