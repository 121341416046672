import axios from "axios";
import React, { useEffect, useState } from "react";
import { getReviewApi } from "../../../utils/apiRoute";
import { getLocals } from "../../../getLocal";
import { hostNameFunction } from "../../../utils/host-function";
import Link from "next/link";
import { formatReviewDate } from "../../../utils/common-functions";

export default function Reviews({ businessSlug, fontSize }) {
  const hostname = hostNameFunction();
  const [reviewData, setReviewData] = useState([]);

  const [localizedStrings, setLocalizedStrings] = useState();

  useEffect(() => {
    const fetchLocalizedStrings = async () => {
      const lang = hostNameFunction() || "en";
      const strings = await getLocals(lang);
      setLocalizedStrings(strings);
    };

    fetchLocalizedStrings();
  }, []);

  useEffect(() => {
    async function getReviewDetails() {
      try {
        const apiUrl = businessSlug
          ? `${getReviewApi}?slug=${businessSlug}`
          : getReviewApi;
        const response = await axios.get(apiUrl, { params: { lan: hostname } });

        // Filter out duplicate reviews based on name and review comment
        const uniqueReviews = filterOutDuplicates(response.data);
        setReviewData(uniqueReviews);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    }

    getReviewDetails();
  }, [businessSlug]);

  function filterOutDuplicates(reviews) {
    const uniqueReviewsMap = new Map();

    reviews.forEach((review) => {
      // Construct a unique identifier using name and review comment
      const uniqueIdentifier = `${review.name}_${review.review_comment}`;

      // If the unique identifier doesn't exist in the map, add the review
      if (!uniqueReviewsMap.has(uniqueIdentifier)) {
        uniqueReviewsMap.set(uniqueIdentifier, review);
      }
    });

    // Convert map values back to an array
    const uniqueReviews = Array.from(uniqueReviewsMap.values());
    return uniqueReviews;
  }
  return (
    <>
      <div className="pb-10">
        <div>
          <b className={`md:${fontSize}pb-2`}>
            {localizedStrings && localizedStrings.Index.reviewSubHeading}
          </b>
        </div>
        <div className="flex flex-col space-y-3 items-center">
          {reviewData.map((review, index) => (
            // Parse the review date string to a Date object
            <div
              key={index}
              className="flex bg-clip-border shadow-md w-[100%] bg-white justify-between border-4 border-l-indigo-500 p-4 my-2 flex-col"
            >
              <div>
                <p className="text-base">
                  <Link href={`/places/${review.businessSlug}`}>
                    <b className="hover:text-orange-500">
                      {review.businessName}
                    </b>
                  </Link>
                </p>
                <p className="text-base w-[80%]">{review.review_comment}</p>
              </div>
              <div className="flex flex-col justify-end items-end">
                <div className="flex justify-end" key={" "}>
                  {Array.from({ length: review.review_rating }).map((_, i) => (
                    <svg
                      key={i}
                      className="w-4 h-4 text-yellow-300 ms-1"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 20"
                    >
                      <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                    </svg>
                  ))}

                  {/* empty stars */}
                  {Array.from({ length: 5 - review.review_rating }).map(
                    (_, i) => (
                      <svg
                        key={i}
                        className="w-4 h-4 ms-1 text-gray-300 dark:text-gray-500"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 22 20"
                      >
                        <path d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z" />
                      </svg>
                    )
                  )}
                </div>
                <div className="flex">
                  <div className="text-base">
                    {review.name}, {formatReviewDate(review.review_date)}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
