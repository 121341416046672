// Define a function to format a category string
export function formatCategory(category) {
  // Split the string into an array of words
  const words = category.split("-");

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the capitalized words with spaces
  const formattedCategory = capitalizedWords.join(" ");

  // Return the formatted category
  return formattedCategory;
}

export function formatReviewDate(reviewDate) {
  // Convert the date string to a Date object
  const date = new Date(reviewDate);

  // Format the date
  const formattedDate = date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return formattedDate;
}

export function encodeSlug(slug) {
  const encodedSlug = encodeURIComponent(slug);
  return encodedSlug;
}
