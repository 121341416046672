//src/components/home/CategoryListing.jsx
import axios from "axios";
import React, { useEffect, useState } from "react";
import { MdSpaceDashboard } from "react-icons/md";
import pluralize from "pluralize";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import SkeletonTest from "../common/skeletonTest";
import { getCategoryApi } from "../../../utils/apiRoute";
import Image from "next/image";
import { Card } from "@material-tailwind/react";
import { TooltipWithHelperIcon } from "../common/tooltip";
import { getLocals } from "../../../getLocal";
import { hostNameFunction } from "../../../utils/host-function";
import { countApi } from "../../../utils/apiRoute";

export default function CategoryListing({ countryName, countryTranslations }) {
  const hostname = hostNameFunction();
  const [categoryData, categorySetData] = useState([]);
  const [showCard, setShowCard] = useState({});
  const [localizedStrings, setLocalizedStrings] = useState();
  const [categoryCount, setCategoryCount] = useState([]);
  let formattedCountryName = countryName
    ? countryName.toLowerCase().replace(/ /g, "-")
    : "default-country"; // Provide a default value
  useEffect(() => {
    const fetchLocalizedStrings = async () => {
      const lang = hostname ? hostname : "en";
      const strings = await getLocals(lang);
      setLocalizedStrings(strings);
    };

    fetchLocalizedStrings();
  }, []);
  const { t } = useTranslation();

  useEffect(() => {
    async function getDetails() {
      try {
        const response = await axios.post(getCategoryApi, {
          lan: hostname,
        });

        if (response.data.length > 0) {
          const updatedData = response.data.map((category) => {
            // Filter unique businesses from firstFiveBusinesses
            const uniqueBusinesses = category.firstFiveBusinesses.reduce(
              (acc, curr) => {
                const index = acc.findIndex(
                  (item) => item.businessName === curr.businessName
                );
                if (index === -1) {
                  acc.push(curr);
                }
                return acc;
              },
              []
            );

            // Return the category with only 5 unique businesses
            return {
              ...category,
              firstFiveBusinesses: uniqueBusinesses.slice(0, 5),
            };
          });

          // Set the updated data to the state
          categorySetData(updatedData);
        }
      } catch (error) {
        console.error("Error");
      }
    }

    if (categoryData.length == 0) {
      getDetails();
    }
  }, []);

  useEffect(() => {
    async function getCategoryCount() {
      try {
        const res = await axios.post(countApi);
        setCategoryCount(res.data);
      } catch (error) {
        console.error("Error fetching category count:", error);
      }
    }

    getCategoryCount();
  }, []); // The empty array ensures this runs only once after the initial render

  const handleMouseOver = (businessSlug) => {
    setShowCard((prevState) => ({
      [businessSlug]: true, // Set the state for the specific link to show the card
    }));
  };

  const handleMouseLeave = (businessSlug) => {
    setShowCard((prevState) => ({
      [businessSlug]: false, // Set the state for the specific link to hide the card
    }));
  };

  return (
    <>
      <div className="text-justify">
        <p className="pb-2 md:text-[1.17em] text-xl">
          {hostname == "hi" ? (
            <b>
              {localizedStrings && localizedStrings.Index.categoryHeading2}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryHeading1}
            </b>
          ) : (
            <b>
              {localizedStrings && localizedStrings.Index.categoryHeading1}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryHeading2}
            </b>
          )}
        </p>
        <div className="min-h-[120px] md:text-lg pb-1 w-full my-4 text-start">
          {hostname == "hi" ? (
            <p className="block">
              {localizedStrings && localizedStrings.Index.categoryFirstLine1}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {categoryCount?.[0]?.totalCategories}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine2}{" "}
            </p>
          ) : (
            <p className="block">
              {localizedStrings && localizedStrings.Index.categoryFirstLine1}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine2}{" "}
              {categoryCount?.[0]?.totalCategories}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine3}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}.{" "}
            </p>
          )}
          <div class="inline">
            {countryTranslations?.[1]}{" "}
            {hostname == "hi"
              ? localizedStrings && localizedStrings.Index.categoryFirstLine4
              : ""}
            {hostname == "hi"
              ? ""
              : localizedStrings &&
                localizedStrings.Index.categorySecondLine1}{" "}
            {categoryData.length > 0 &&
              categoryData.map((con, index) => (
                <React.Fragment key={index}>
                  {con.categoryCount.toLocaleString()} {con.categoryNamePlural}
                  {index !== categoryData.length - 1 && ","}{" "}
                </React.Fragment>
              ))}
            {localizedStrings && localizedStrings.Index.categorySecondLine2}
            {hostname == "hi" ? " " : ""}
            {hostname == "hi" ? "" : ". "}
            {hostname == "hi"
              ? localizedStrings && localizedStrings.Index.categorySecondLine1
              : ""}{" "}
          </div>
          {hostname == "hi" ? (
            <p className="block">
              {localizedStrings && localizedStrings.Index.categoryThirdLine3}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {localizedStrings && localizedStrings.Index.categoryThirdLine1}
            </p>
          ) : (
            <p className="block">
              {localizedStrings && localizedStrings.Index.categoryThirdLine1}{" "}
              {localizedStrings && localizedStrings.Index.categoryFirstLine4}{" "}
              {countryTranslations?.[0]}{" "}
              {localizedStrings && localizedStrings.Index.categoryThirdLine3}.
            </p>
          )}
        </div>
      </div>
      {categoryData.length === 0 ? (
        <SkeletonTest />
      ) : (
        <div className="relative shadow-md sm:rounded-lg my-5 lg:block overflow-auto">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-base text-white bg-blue-500 hidden md:table-header-group">
              <tr>
                <th scope="col" className="px-6 py-3">
                  {localizedStrings && localizedStrings.Index.firstColumn}
                </th>
                <th scope="col" className="px-6 py-3 ">
                  {localizedStrings && localizedStrings.Index.secondColumn}
                </th>
                <th scope="col" className="px-6 py-3">
                  {localizedStrings && localizedStrings.Index.thirdPlaces}
                </th>
              </tr>
            </thead>
            <tbody>
              {categoryData.length > 0 &&
                categoryData.map((category, index) => (
                  <tr
                    className="bg-white hover:bg-lime-50 hover:border-blue-400 hover:border-[1px] flex md:table-row flex-wrap justify-between"
                    key={index}
                  >
                    <th
                      scope="row"
                      className="hidden md:table-cell px-6 py-4 font-normal whitespace-nowrap text-white bg-blue-500 md:bg-transparent"
                    >
                      <div className="flex">
                        <Image
                          src={`/assets/img/icons/${category.icon}`}
                          className="w-8 h-8"
                          height={500}
                          width={500}
                          alt="image"
                        />

                        <Link
                          className="text-xl s text-white md:text-blue-500  pr-1 pl-2"
                          href={`/category/${category.categorySlug}-in-${formattedCountryName}`}
                        >
                          {category.categoryNamePlural
                            .split(/\s+/)
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}
                        </Link>
                      </div>
                    </th>

                    <td className="hidden md:table-cell px-6 py-4 md:text-xl text-2xl text-white md:text-gray-800  bg-blue-500 md:bg-transparent">
                      {category.categoryCount.toLocaleString()}
                    </td>

                    {/* for the mobile device only start */}
                    <td className="flex flex-wrap justify-between bg-blue-500  items-center px-4 md:hidden w-full">
                      <div className="flex w-3/5 border-r-2 py-2 items-center">
                        <Image
                          src={`/assets/img/icons/${category.icon}`}
                          className="h-8 w-8"
                          height={500}
                          width={500}
                          alt="image"
                        />

                        <Link
                          className="text-[1.2em] md:text-lg text-white md:text-blue-500  pr-1 pl-2"
                          href={`/category/${category.categorySlug}-in-${formattedCountryName}`}
                        >
                          {hostname
                            ? category.categoryName
                            : pluralize.plural(category.categoryName)}
                        </Link>
                      </div>

                      <div className="w-2/5 text-[1.2em] md:text-lg text-end text-white py-2">
                        {category.categoryCount.toLocaleString()}
                      </div>
                    </td>
                    {/* for the mobile device only end */}

                    <td className="p-2 md:space-y-0">
                      {category.firstFiveBusinesses.length > 0 &&
                        category.firstFiveBusinesses.map(
                          (businessDetails, index) => (
                            <div className="flex items-baseline" key={index}>
                              <div className="pr-2 ">
                                <MdSpaceDashboard className="text-[1.2em] md:text-lg" />
                              </div>

                              <div className="pr-2 relative leading-[2.2em] md:leading-10">
                                <Link
                                  href={`/places/${businessDetails.businessSlug}`}
                                  className=" text-blue-500"
                                  onMouseOver={() =>
                                    handleMouseOver(
                                      businessDetails.businessSlug,
                                      businessDetails
                                    )
                                  }
                                >
                                  <b className="text-[1.2em] md:text-lg">
                                    {businessDetails.businessName}
                                  </b>
                                </Link>{" "}
                                {""}
                                {showCard[businessDetails.businessSlug] && (
                                  <Card
                                    className={`mt-6 w-96 absolute md:right-24 md:top-0 z-50  rounded-sm`}
                                    onMouseLeave={() =>
                                      handleMouseLeave(
                                        businessDetails.businessSlug
                                      )
                                    }
                                  >
                                    <TooltipWithHelperIcon
                                      props={businessDetails}
                                    />
                                  </Card>
                                )}
                                <span className="text-[1.2em] md:text-lg ">
                                  {businessDetails.address}
                                </span>
                              </div>
                            </div>
                          )
                        )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex justify-center pt-6">
        <Link href={`/${formattedCountryName}/top-categories`}>
          <button
            type="button"
            className="text-white bg-[#0d6efd] hover:bg-blue-800 focus:ring-4 focus:ring-blue-300
            rounded-lg md:px-5 p-5 md:py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none
             dark:focus:ring-blue-800 md:text-xl text-base pt-1 pb-1 font-bold"
          >
            {localizedStrings && localizedStrings.Index.exploreBtn}
          </button>
        </Link>
      </div>
    </>
  );
}
